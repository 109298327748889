import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CampusSelectListItem } from '@app/core/models/select-list-items/CampusSelectListItem';
import { CampusLogoModel, CampusListItem, CampusLogo } from '@app/core/models/campuses/campusListItem';
import { LinkCampusToClientRequest } from '@app/core/models/campuses/linkCampusToClientRequest';

@Injectable({
  providedIn: 'root'
})
export class CampusService {

  constructor(
    private http: HttpClient
  ) { }

  getCampusSelectListItems(clientId: number, includeDisabled: boolean = true): Observable<CampusSelectListItem[]> {
    return this.http.get<CampusSelectListItem[]>(
      `${environment.apiUrl}Campuses/campus-select-list/${clientId}?includeDisabled=${includeDisabled}`);
  }

  linkCampusToClient(request: LinkCampusToClientRequest, campusId: number) {
    return this.http.put(`${environment.apiUrl}Campuses/${campusId}/link-client`, request)
  }

  addCampusLogo(model: CampusLogoModel){
    const formData = new FormData();
    Object.keys(model)
      .forEach(key => formData.append(key, model[key]));

      return this.http.post(`${environment.apiUrl}Campuses/${model.campusId}/add-campus-logo`, formData);
  }

  replaceCampusLogo(model: CampusLogoModel, campusId: number){
    const formData = new FormData();
    Object.keys(model)
      .forEach(key => formData.append(key, model[key]));

      return this.http.post(`${environment.apiUrl}Campuses/${campusId}/update-campus-logo`, formData);
  }

  getCampusListItems(clientId:number): Observable<CampusListItem[]>{
    return this.http.get<CampusListItem[]>(
      `${environment.apiUrl}Campuses/campus-list/${clientId}`);
  }

  getCampusLogo(campusId: number){
    return this.http.get<CampusLogo>(`${environment.apiUrl}Campuses/${campusId}/campus-logo`);
  }
}
