import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule) },
  { path: 'account', loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule) },
  { path: 'admin', loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule) },
  { path: 'claim-management', loadChildren: () => import('./features/claim-management/claim-management.module').then(m => m.ClaimManagementModule) },
  { path: 'accident-claim-form', loadChildren: () => import('./features/accident-claim-form/accident-claim-form.module').then(m => m.AccidentClaimFormModule) },
  { path: 'library', loadChildren: () => import('./features/library/library.module').then(m => m.LibraryModule) },
  { path: 'reports', loadChildren: () => import('./features/reports/reports.module').then(m => m.ReportsModule) },
  { path: 'general-contact-us', loadChildren: () => import('./features/general-contact-us/general-contact-us.module').then(m => m.GeneralContactUsModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
