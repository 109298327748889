import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BrokerAgency, BrokerAgencyDetails } from "@app/core/models/broker-agency/brokerAgency";
import { BrokerAgencyListItem, BrokerAgencyLogo } from "@app/core/models/broker-agency/brokerAgencyListItem";
import { CandidateBrokerAgency } from "@app/core/models/broker-agency/candidateBrokerAgency";
import { BrokerAgencySelectListItem } from "@app/core/models/select-list-items/brokerAgencySelectListItem";
import { Broker } from "@app/core/models/users/broker";
import { CandidateBroker } from "@app/core/models/users/candidateBroker";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class BrokerService {
    constructor(
        private http: HttpClient
    ) { }

    getBrokerAgencySelectListItems(includeDisabled: boolean = true): Observable<BrokerAgencySelectListItem[]>{
        return this.http.get<BrokerAgencySelectListItem[]>(
            `${environment.apiUrl}Brokers/agency-select-list?includeDisabled=${includeDisabled}`);
    }

    submitBroker(candidate: CandidateBroker, id?: number){
        return id
            ? this.http.put<number>(`${environment.apiUrl}Brokers/${id}`, candidate)
            : this.http.post<number>(`${environment.apiUrl}Brokers/add-broker`, candidate);
    }

    getUserById(id: number): Observable<Broker>{
        return this.http.get<Broker>(
            `${environment.apiUrl}Brokers/${id}`);
    }

    getBrokerAgencyListItems(includeDisabled: boolean = true): Observable<BrokerAgencyListItem[]>{
      return this.http.get<BrokerAgencyListItem[]>(
        `${environment.apiUrl}Brokers/agency-list?includeDisabled=${includeDisabled}`);
    }

    addBrokerAgency(candidate: CandidateBrokerAgency): Observable<number>{
      const formData = new FormData();
      Object.keys(candidate)
        .forEach(key => formData.append(key, candidate[key]));

      return this.http.post<number>(`${environment.apiUrl}Brokers/add-broker-agency`, formData);
    }

    getBrokerAgency(id: number): Observable<BrokerAgency>{
      return this.http.get<BrokerAgency>(
        `${environment.apiUrl}Brokers/agency/${id}`);
    }

    updateBrokerAgency(candidate: CandidateBrokerAgency): Observable<number>{
      const formData = new FormData();
      Object.keys(candidate)
        .forEach(key => formData.append(key, candidate[key]));

      return this.http.put<number>(`${environment.apiUrl}Brokers/update-agency`, formData);
    }

    getBrokerAgencyLogo(brokerAgencyId: number){
      return this.http.get<BrokerAgencyLogo>(`${environment.apiUrl}Brokers/agency-logo/${brokerAgencyId}`)
    }

    getBrokerAgencyDetails(brokerAgencyId: number): Observable<BrokerAgencyDetails>{
      return this.http.get<BrokerAgencyDetails>(
        `${environment.apiUrl}Brokers/agency-details/${brokerAgencyId}`);
    }
}
