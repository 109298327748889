<mat-nav-list [ngClass]="open ? 'open' : 'closed'">
  <a
    mat-list-item
    [routerLink]="['/admin/users']"
    routerLinkActive="router-link-active"
    *appRequireClaim="'ViewUsers'">
    <mat-icon fontSet="fas" fontIcon="fa-user" matTooltip="Users"></mat-icon>
    <span>Users</span>
  </a>
  <a
    mat-list-item
    [routerLink]="['/claim-management/accidents']"
    routerLinkActive="router-link-active"
    *appRequireClaim="'SearchAccidents'">
    <mat-icon fontSet="fas" fontIcon="fa-briefcase-medical" matTooltip="Search"></mat-icon>
    <span>Search</span>
  </a>
  <a
    mat-list-item
    [routerLink]="['/accident-claim-form']"
    routerLinkActive="router-link-active"
    *appRequireClaim="'ViewAccidentClaimForms'"
  >
    <mat-icon fontSet="fas" fontIcon="fa-file-medical" matTooltip="Accident Claim Forms"></mat-icon>
    <span>Accident Claim Forms</span>
  </a>
  <a
    mat-list-item
    [routerLink]="['/admin/clients']"
    routerLinkActive="router-link-active"
    *appRequireClaim="'ViewClients'">
      <mat-icon fontSet="fas" fontIcon="fa-landmark" matTooltip="Clients"></mat-icon>
      <span>Client Info</span>
  </a>
  <a
    mat-list-item
    [routerLink]="['/admin/broker-agencies']"
    routerLinkActive="router-link-active"
    *appRequireClaim="'ManageBrokerAgencies'">
      <mat-icon fontSet="fas" fontIcon="fa-building"></mat-icon>
      <span>Broker Agency Info</span>
  </a>
  <a
    mat-list-item
    [routerLink]="['/admin/claims-examiners']"
    routerLinkActive="router-link-active"
    *appRequireClaim="'ViewClaimsExaminers'">
      <mat-icon fontSet="fas" fontIcon="fa-user-tie"></mat-icon>
      <span>Claims Examiner Info</span>
  </a>
  <a
    mat-list-item
    [routerLink]="['/library']"
    routerLinkActive="router-link-active"
    *appRequireClaim="'ViewLibraryDocuments'">
    <mat-icon fontSet="fas" fontIcon="fa-book-open" matTooltip="Library"></mat-icon>
    <span>Library</span>
  </a>



    <div mat-list-item class="ml-3 mb-2" style="cursor: pointer; outline: none;">
      <a [routerLink]="['/general-contact-us/form']"
        routerLinkActive="router-link-active"
        *appRequireClaim="'SendGeneralInquiry'"
        class="pt-2 pb-2">
        <mat-icon fontSet="fas" fontIcon="fa-envelope" matTooltip="Contact Us"></mat-icon>
        <ng-container *ngIf="open">
          <span style="display:inline-block; width:75%; color:rgba(0, 0, 0, 0.87);" class="pl-1">Contact Us</span>
        </ng-container>
      </a>
      <span class="mr-auto" (click)="toggleContactUsMenuItems()">
        <mat-icon *ngIf="showContactUsMenuItems">keyboard_arrow_up</mat-icon>
        <mat-icon *ngIf="!showContactUsMenuItems">keyboard_arrow_down</mat-icon>
      </span>
    </div>
    <mat-nav-list [hidden]="!showContactUsMenuItems">
      <ng-container *ngTemplateOutlet="contactUs"></ng-container>
    </mat-nav-list>


  <ng-container *appRequireClaim="'ViewReports'">
    <a mat-list-item (click)="toggleReportsMenuItems()">
      <mat-icon fontSet="fas"
                fontIcon="fa-folder"
                matTooltip="Reports"></mat-icon>
      <span>Reports</span>
      <span class="ml-auto">
        <mat-icon *ngIf="showReportsMenuItems">keyboard_arrow_up</mat-icon>
        <mat-icon *ngIf="!showReportsMenuItems">keyboard_arrow_down</mat-icon>
      </span>
    </a>
    <mat-nav-list [hidden]="!showReportsMenuItems">
      <ng-container *ngTemplateOutlet="reports"></ng-container>
    </mat-nav-list>
  </ng-container>

  <a
    mat-list-item
    [routerLink]="['/admin/data']"
    routerLinkActive="router-link-active"
    *appRequireClaim="'ViewDataManagement'">
    <mat-icon fontSet="fas" fontIcon="fa-database" matTooltip="Data Management"></mat-icon>
    <!-- <mat-icon fontSet="fas" fontIcon="fa-check" matTooltip="Job is enabled" color="success" style="font-size: 12px;"></mat-icon> -->
    <span>Data Management</span>
  </a>

</mat-nav-list>


  <ng-template #reports>
    <a mat-list-item [routerLink]="['/reports/claim-form-report']" routerLinkActive="router-link-active"
      *appRequireClaim="'RunClaimFormReport'">
      <mat-icon fontSet="fas" fontIcon="fa-file"
        matTooltip="Claim Form Report"></mat-icon>
      <span>Claim Form Report</span>
    </a>
    <a mat-list-item [routerLink]="['/reports/claim-detail-report']" routerLinkActive="router-link-active"
      *appRequireClaim="'ViewClaimDetailReport'">
      <mat-icon fontSet="fas" fontIcon="fa-file-alt"
        matTooltip="Claim Detail Report"></mat-icon>
      <span>Claim Detail Report</span>
    </a>
    <a mat-list-item [routerLink]="['/reports/eob-report']" routerLinkActive="router-link-active"
      *appRequireClaim="'RunEobReports'">
      <mat-icon fontSet="fas" fontIcon="fa-file-invoice"
        matTooltip="EOB Report"></mat-icon>
      <span>EOB Report</span>
    </a>
  </ng-template>


<ng-template #contactUs>
  <a mat-list-item [routerLink]="['/general-contact-us/inquiries']" routerLinkActive="router-link-active"
    *appRequireClaim="'ViewGeneralInquiries'">
    <mat-icon fontSet="fas" fontIcon="fa-paper-plane"
      matTooltip="Past Inquiries"></mat-icon>
      <span>Past Inquiries</span>
  </a>
</ng-template>
