import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './core/security/auth.service';
import { Subject, of, combineLatest } from 'rxjs';
import { takeUntil, map, delay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDrawerMode } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { UpdateMonitorService } from './core/services/site-update-monitor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit, OnDestroy {
  title = 'bmi-claimsportal-web';
  navIsVisible = false;
  navMode: MatDrawerMode = 'side';
  mobileNavOpen = false;
  year = new Date().getFullYear();
  version = environment.appVersion;

  private unsubscribe = new Subject<void>();
  private _menuExpanded = true;

  constructor(
    private authService: AuthService,
    private breakpoints: BreakpointObserver,
    private router: Router,
    updateMonitor: UpdateMonitorService) {
  }

  get menuExpanded(): boolean {
    return this._menuExpanded || this.navMode === 'over';
  }

  toggleMenu() {
    this._menuExpanded = !this._menuExpanded;
  }

  ngOnInit() {
    const isLoggedIn$ = this.authService.isLoggedIn();
    combineLatest([
      isLoggedIn$,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .pipe(map(([isLoggedIn]) => {
        return isLoggedIn;
      }))
      .pipe(delay(0))
      .subscribe(isVisible => {
        this.navIsVisible = isVisible;
        this._menuExpanded = isVisible;
      });

    this.breakpoints.observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        if (result.matches) {
          this.navMode = 'over';
          this.mobileNavOpen = false;
        } else {
          this.navMode = 'side';
        }
    });

    this.router.events
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.mobileNavOpen = false;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
