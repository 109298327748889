import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../security/auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.isLoggedIn()
      .pipe(switchMap(isLoggedIn => {
        if (isLoggedIn) {
          request = request.clone({
            setHeaders: {
              Authorization: this.authService.getAuthorizationHeaderValue()
            }
          });
        }
        return next.handle(request);
      }));
  }
}
