import { ApplicationRef, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { interval, concat, Observable } from 'rxjs';
import { map, take, first } from 'rxjs/operators';
import { ErrorMsgComponent } from 'src/app/shared/components/error-msg/error-msg.component';

@Injectable({
  providedIn: 'root'
})
export class UpdateMonitorService {
constructor(
    updates: SwUpdate,
    appRef: ApplicationRef,
    private snackBar: MatSnackBar
  ) {
    if (!updates.isEnabled) { return; }

    updates.available.subscribe(
      event => {
        console.log('update detected');
        this.promptUserToUpdate(event)
        .pipe(take(1))
        .subscribe(resp => {
          if (resp) {
            console.log('activating update, refreshing page when done');
            updates.activateUpdate().then(() => document.location.reload());
          }
        });
      }
    )

    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
  }

  private promptUserToUpdate(_: UpdateAvailableEvent): Observable<boolean> {
    return this.snackBar.open(`An update is available, please reload to install.`, 'Install Now', { duration: 0 })
      .afterDismissed()
      .pipe(map(resp => resp.dismissedByAction));
  }
}
