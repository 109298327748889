import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, EventEmitter, Output, Input } from '@angular/core';
import { ApplicationRole, ApplicationUser } from '@app/core/security/applicationUser';
import { Subject } from 'rxjs';
import { AuthService } from '@app/core/security/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { ClientService } from '@app/core/services/api/client.service';
import { CampusService } from '@app/core/services/api/campus.service';
import { BrokerService } from '@app/core/services/api/broker.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit, OnDestroy {
  @ViewChild('confirmLogoutDialog') confirmLogoutDialogTemplate: TemplateRef<any>;

  @Input() showMobileNavIcon: boolean;
  @Output() mobileNavToggled = new EventEmitter<void>();

  logoBase64Uri = null;
  user: ApplicationUser;
  private unsubscribe = new Subject<void>();
  clientName = null;
  loadingCount = 0;

  constructor(
    public authService: AuthService,
    public logoffDialog: MatDialog,
    public clientService: ClientService,
    public campusService: CampusService,
    public brokerService: BrokerService) { }

  ngOnInit() {
    this.authService.user$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(user => {
      this.user = user;
      this.initializeClientLogo(user);
      this.displayClientName(user?.role, user?.clientId);
    });
  }

  initializeClientLogo(user: ApplicationUser): void{
    if(user?.campusId){
      this._setCampusLogo(user?.campusId);
    }else if(user?.clientId){
      this._setClientLogo(user?.clientId);
    }else if(user?.brokerAgencyId){
      this._setBrokerAgencyLogo(user?.brokerAgencyId);
    }
  }

  private _setCampusLogo(campusId: number){
    this.loadingCount++;
    this.logoBase64Uri = null;
    this.campusService.getCampusLogo(campusId)
      .pipe(take(1), finalize(() => {this.loadingCount--;}))
      .subscribe(campusLogo => {
        if(campusLogo?.logoFileExtension){
          this.logoBase64Uri = 'data:' + campusLogo.logoMimeType + ';base64,' + campusLogo.logoBase64;
        }else{
          this.logoBase64Uri = null;
        }
      })
  }

  private _setClientLogo(clientId: number){
    this.loadingCount++;
    this.logoBase64Uri = null;
      this.clientService.getClientLogo(clientId)
        .pipe(take(1), finalize(() => {this.loadingCount--;}))
        .subscribe(clientLogo => {
          if(clientLogo.logoFileExtension){
            this.logoBase64Uri = 'data:'+ clientLogo.logoMimeType + ';base64,' + clientLogo.logoBase64;
          }else{
            this.logoBase64Uri = null;
          }
        });
  }

  private _setBrokerAgencyLogo(brokerAgencyId: number){
    this.loadingCount++;
    this.logoBase64Uri = null;
    this.brokerService.getBrokerAgencyLogo(brokerAgencyId)
      .pipe(take(1), finalize(() => {this.loadingCount--;}))
      .subscribe(brokerAgencyLogo => {
        if(brokerAgencyLogo.logoFileExtension){
          this.logoBase64Uri = 'data:' + brokerAgencyLogo.logoMimeType + ';base64,' + brokerAgencyLogo.logoBase64;
        }else{
          this.logoBase64Uri = null;
        }
      });
  }

  displayClientName(userRole: ApplicationRole, clientId: number): void{
    this.clientName = null;
    if((userRole === 'ClientAdmin' || userRole === 'ClientDelegate') && clientId){
      this.loadingCount++;
      this.clientService.getClientName(clientId)
        .pipe(take(1), finalize(() => {this.loadingCount--;}))
        .subscribe(clientName => {
          if(clientName){
            this.clientName = clientName + ' ';
          }else{
            this.clientName = null;
          }
        });
    }
  }

  confirmLogout() {
    const dialogRef = this.logoffDialog.open(this.confirmLogoutDialogTemplate);

    dialogRef.afterClosed().subscribe(
      resp => {
        if(resp && this.logoBase64Uri){
          this.logoBase64Uri = null;
        }
      }
    )
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
