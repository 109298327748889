import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Subject, Subscription, timer } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { ApplicationUser } from "../security/applicationUser";
import { AuthService } from "../security/auth.service";

@Injectable({
  providedIn: 'root'
})
export class SessionExpirationService {
  sessionExpiring$ = new Subject<boolean>();
  sessionExpired$ = new Subject<boolean>();
  expiresAt: moment.Moment;
  notifyAt: Date;
  private sessionTimer: Subscription;
  private timerInitialized = false;

  constructor(
    private authService: AuthService
  ){
    this.authService.user$
      .subscribe(user => this._initializeSessionTimer(user));
  }

  private _initializeSessionTimer(user: ApplicationUser){
    if(!user){
      if(this.sessionTimer) {
        this.timerInitialized = false;
        this.sessionTimer.unsubscribe(); }
      return;
    }

    this.expiresAt = moment(user.accessToken.tokenExpiration);
    this.notifyAt = this.expiresAt.clone().add(-61, 'seconds').toDate();

    if(!this.timerInitialized){
      this.sessionTimer = timer(this.notifyAt, 60000)
      .pipe(takeUntil(this.sessionExpired$))
        .subscribe(_ => {
          if(this.expiresAt.isBefore(moment())) {
            this.timerInitialized = false;
            this.sessionExpired$.next(true);
          } else if (this.expiresAt.isBefore(moment().add(60, 'seconds'))) {
            this.sessionExpiring$.next(true);
          }
        });
        this.timerInitialized = true;
    }
  }

  renewAccessToken(){
    this.authService.renewSession()
      .pipe(take(1))
      .subscribe(_ => {
        this.sessionExpiring$.next(false);
      });
  }
}
