<header class="fixed-top">
  <app-top-nav
    [showMobileNavIcon]="navMode === 'over'"
    (mobileNavToggled)="mobileNavOpen = !mobileNavOpen">
  </app-top-nav>
  <app-session-expiration></app-session-expiration>
</header>
<mat-sidenav-container class="h-100" autosize>
  <mat-sidenav
    [mode]="navMode"
    [opened]="navIsVisible &&
      (navMode === 'side' || (navMode === 'over' && mobileNavOpen))"
    [ngClass]="menuExpanded ? 'open' : 'closed'">
    <nav class="d-flex flex-column h-100">
      <app-main-menu class="flex-grow-1" [open]="menuExpanded"></app-main-menu>
      <button mat-button (click)="toggleMenu()" class="text-right"
        *ngIf="navMode === 'side'">
        <mat-icon *ngIf="menuExpanded">chevron_left</mat-icon>
        <mat-icon *ngIf="!menuExpanded">chevron_right</mat-icon>
      </button>
      <footer class="p-1">
        <small
          [matTooltip]="version"
          matTooltipPosition="above"
          [matTooltipShowDelay]="500">
          &copy; {{ year }}
          <span [hidden]="!menuExpanded">BMI</span>
          <!-- <span>BMI</span> -->
        </small>
      </footer>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content class="p-3">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<mat-table style="display: none">
  <mat-header-row *matHeaderRowDef="[]"></mat-header-row>
</mat-table>
