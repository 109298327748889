import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ErrorMsgComponent } from './components/error-msg/error-msg.component';
import { SaveProgressComponent } from './components/save-progress/save-progress.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MomentDateModule, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTreeModule } from '@angular/material/tree';
import { GridComponent, GridColComponent } from './components/grid/grid.component';
import { SuccessMsgComponent } from './components/success-msg/success-msg.component';
import { UserListComponent } from './components/users/user-list/user-list.component';
import { RequiredMsgComponent } from './components/validation/required-msg/required-msg.component';
import { MaxLengthMsgComponent } from './components/validation/maxlength-msg/maxlength-msg.component';
import { SaveSuccessComponent } from './components/save-success/save-success.component';
import { SaveErrorComponent } from './components/save-error/save-error.component';
import { ValidationErrorComponent } from './components/validation/validation-error/validation-error.component';
import { RequireClaimDirective } from './directives/require-claim.directive';
import { PatternMsgComponent } from './components/validation/pattern-msg/pattern-msg-component';
import { DeleteSuccessComponent } from './components/delete-success/delete-success.component';
import { ConfirmActionComponent } from './components/confirm-action/confirm-action.component';
import { ClientAutocompleteComponent } from './components/client-autocomplete/client-autocomplete.component';
import { DialogContactUsComponent } from './components/dialog-claim-contact-us/dialog-claim-contact-us.component';
import { MemberAutocompleteComponent } from './components/member-autocomplete/member-autocomplete.component';
import { SendSuccessComponent } from './components/send-success/send-success.component';
import { StateAutocompleteComponent } from './components/state-autocomplete/state-autocomplete.component';
import { UpdateSuccessComponent } from './components/update-success/update-success.component';
import { AutocompleteMsgComponent } from './components/validation/autocomplete-msg/autocomplete-msg.component';
import { RequiredInputComponent } from './components/required-input/required-input.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserTypeToggleButtonComponent } from './user-type-toggle-button/user-type-toggle-button.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { UploadSuccessComponent } from './components/upload-success/upload-success.component';
import { ResetUserMfaComponent } from './components/reset-user-mfa/reset-user-mfa.component';
import { ContactClaimInquiriesComponent } from './components/contact-claim-inquiries/contact-claim-inquiries.component';
import { ReadMoreDirective } from './directives/read-more.directive';
import { PayorMultiselectComponent } from './components/payor-multiselect/payor-multiselect.component';
import { FileDragDropDirective } from './directives/file-drag-drop.directive';
import { ClientMultiselectComponent } from './components/client-multiselect/client-multiselect.component';


@NgModule({
  declarations: [
    RequireClaimDirective,
    ErrorMsgComponent,
    SaveProgressComponent,
    SaveSuccessComponent,
    DeleteSuccessComponent,
    SaveErrorComponent,
    GridComponent,
    GridColComponent,
    SuccessMsgComponent,
    UserListComponent,
    RequiredMsgComponent,
    MaxLengthMsgComponent,
    AutocompleteMsgComponent,
    ValidationErrorComponent,
    PatternMsgComponent,
    ConfirmActionComponent,
    ClientAutocompleteComponent,
    MemberAutocompleteComponent,
    DialogContactUsComponent,
    SendSuccessComponent,
    StateAutocompleteComponent,
    UpdateSuccessComponent,
    RequiredInputComponent,
    UserProfileComponent,
    UserTypeToggleButtonComponent,
    SetPasswordComponent,
    UploadSuccessComponent,
    ResetUserMfaComponent,
    ContactClaimInquiriesComponent,
    ReadMoreDirective,
    PayorMultiselectComponent,
    FileDragDropDirective,
    ClientMultiselectComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatDividerModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatAutocompleteModule,
    MatListModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTabsModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatExpansionModule,
    MatDatepickerModule,
    MomentDateModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatTreeModule
  ],
  exports: [
    RequireClaimDirective,
    ValidationErrorComponent,
    MaxLengthMsgComponent,
    AutocompleteMsgComponent,
    RequiredMsgComponent,
    PatternMsgComponent,
    UserListComponent,
    SuccessMsgComponent,
    SaveSuccessComponent,
    UploadSuccessComponent,
    DeleteSuccessComponent,
    SaveErrorComponent,
    ConfirmActionComponent,
    RouterModule,
    GridComponent,
    GridColComponent,
    ErrorMsgComponent,
    SaveProgressComponent,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDividerModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTabsModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatExpansionModule,
    MatDatepickerModule,
    MomentDateModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatTreeModule,
    ClientAutocompleteComponent,
    MemberAutocompleteComponent,
    StateAutocompleteComponent,
    RequiredInputComponent,
    UserProfileComponent,
    UserTypeToggleButtonComponent,
    SetPasswordComponent,
    ResetUserMfaComponent,
    ReadMoreDirective,
    PayorMultiselectComponent,
    FileDragDropDirective,
    ClientMultiselectComponent
  ],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 }
        },
        {
          provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' }
        }
      , DatePipe]
    };
  }
}
