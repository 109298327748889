<ng-template #snackBarTemplate>
  <div class="mat-simple-snackbar">
    <span>Your session is about to end. Do you want to stay logged in?</span>
    <div class="mat-simple-snackbar-action">
      <button class="mt-2" type="button" color="primary" mat-raised-button (click)="renewSession()">
        <ng-container>Yes, keep me logged in</ng-container>
      </button>
    </div>
  </div>
</ng-template>
