import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { AuthTokenInterceptor } from './interceptors/auth-token.interceptor';
import { DateConversionInterceptor } from './interceptors/date-conversion.interceptor';
import { SessionExpirationComponent } from './components/session-expiration/session-expiration.component';


@NgModule({
  declarations: [
    TopNavComponent,
    MainMenuComponent,
    SessionExpirationComponent
  ],
  imports: [
    HttpClientModule,
    SharedModule
  ],
  exports: [
    TopNavComponent,
    MainMenuComponent,
    SessionExpirationComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateConversionInterceptor, multi: true },
  ]
})
export class CoreModule { }
