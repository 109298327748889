import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  @Input() open = true;
  showReportsMenuItems: boolean = false;
  showContactUsMenuItems: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleReportsMenuItems() {
    this.showReportsMenuItems = !this.showReportsMenuItems;
  }

  toggleContactUsMenuItems(){
    this.showContactUsMenuItems = !this.showContactUsMenuItems;
  }

}
