import { Permission } from './permission';

export class ApplicationUser {
  constructor(
    public id: number,
    public userName: string,
    public name: string,
    public email: string,
    public role: ApplicationRole,
    public permissions: Permission[],
    public authMethods: AuthenticationMethod[],
    public accessToken: AccessToken,
    public clientId: number,
    public title: string,
    public campusId: number,
    public brokerAgencyId: number,
    public phone: string
  ){}
}

export class AccessToken {
  tokenValue: string;
  tokenExpiration: Date;
}

export type AuthenticationMethod =
  'pwd'
  | 'mfa'
  | 'otp';

export type ApplicationRole =
  'BmiAdmin'
  | 'BmiInternal'
  | 'ClientAdmin'
  | 'ClientDelegate'
  | 'AccidentClaimFormsOnly'
  | 'Broker';
