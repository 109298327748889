import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { CandidateBmiAdmin } from "@app/core/models/users/candidateBmiAdmin";
import { HttpClient } from "@angular/common/http";
import { CandidateClientAdmin } from "@app/core/models/users/candidateClientAdmin";
import { ClientSelectListItem } from "@app/core/models/select-list-items/clientSelectListItem";
import { Observable } from "rxjs";
import { ClientListItem } from "@app/core/models/clients/clientListItem";
import {
  ClientDetails,
  UserListItem,
} from "@app/core/models/clients/clientDetails";
import {
  ClientLogo,
  RequestClientUpdateModel,
} from "@app/core/models/clients/client";
import { ISelectListItem } from "@app/core/models/select-list-items/iSelectListItem";
import { ApplicationUser } from "@app/core/security/applicationUser";

@Injectable({
  providedIn: "root",
})
export class ClientService {
  constructor(private http: HttpClient) {}

  getClientSelectListItems(
    includeDisabled: boolean = true
  ): Observable<ClientSelectListItem[]> {
    return this.http.get<ClientSelectListItem[]>(
      `${environment.apiUrl}Clients/client-select-list?includeDisabled=${includeDisabled}`
    );
  }

  getClients(includeDisabled: boolean = true): Observable<ClientListItem[]> {
    return this.http.get<ClientListItem[]>(
      `${environment.apiUrl}Clients/client-list/?includeDisabled=${includeDisabled}`
    );
  }

  getClientDetails(clientId: number): Observable<ClientDetails> {
    return this.http.get<ClientDetails>(
      `${environment.apiUrl}Clients/${clientId}`
    );
  }

  updateClient(model: RequestClientUpdateModel, isLogoOnly: boolean) {
    const formData = new FormData();
    Object.keys(model).forEach((key) => {
      if (model[key] != null) {
        formData.append(key, model[key]);
      }
    });
    return this.http.post(
      `${environment.apiUrl}Clients/${
        isLogoOnly ? "save-client-logo" : "save"
      }`,
      formData
    );
  }

  getClientLogo(clientId: number) {
    return this.http.get<ClientLogo>(
      `${environment.apiUrl}Clients/${clientId}/client-logo`
    );
  }

  getClientName(clientId: number) {
    const requestOptions: Object = {
      responseType: "text",
    };

    return this.http.get<string>(
      `${environment.apiUrl}Clients/${clientId}/client-name`,
      requestOptions
    );
  }

  getClientBrokerContacts(clientId: number) {
    return this.http.get<UserListItem[]>(
      `${environment.apiUrl}Clients/${clientId}/broker-contacts`
    );
  }

  initializeShowingClientFilter(
    clientSelectListItems: ISelectListItem[],
    user: ApplicationUser
  ): boolean {
    if (user.role === "ClientAdmin" || user.role === "ClientDelegate") {
      return clientSelectListItems.length > 1;
    } else {
      return true;
    }
  }
}
