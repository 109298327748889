import { Component, EmbeddedViewRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SessionExpirationService } from '@app/core/services/session-expiration.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-session-expiration',
  templateUrl: './session-expiration.component.html',
  styleUrls: ['./session-expiration.component.scss']
})
export class SessionExpirationComponent implements OnInit, OnDestroy {
  @ViewChild('snackBarTemplate') snackBarTemplate: TemplateRef<any>;
  private snackBarRef: MatSnackBarRef<EmbeddedViewRef<any>>;
  private unsubscribe = new Subject<void>();

  constructor(
    private sessionExpirationService: SessionExpirationService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.sessionExpirationService.sessionExpiring$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isExpiring) => {
        if(isExpiring){
          this.snackBarRef = this.snackBar.openFromTemplate(this.snackBarTemplate, { duration: 60000 });
        }
      });

      this.sessionExpirationService.sessionExpired$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          if(this.snackBarRef){
            this.snackBarRef.dismiss();
          }
          this.router.navigate(['/account/logout']);
        });
  }

  renewSession(){
    this.sessionExpirationService.renewAccessToken();
    this.snackBarRef.dismiss();
  }

  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
