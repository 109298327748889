import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../core/security/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Directive({
  selector: '[appRequireClaim]'
})
export class RequireClaimDirective implements OnInit, OnDestroy {
  private unsubscribe = new Subject<void>();
  hasPermission = false;
  constructor(private authService: AuthService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) { }

  // tslint:disable-next-line:no-input-rename
  @Input('appRequireClaim') claimName: string;

  ngOnInit(): void {
    this.authService.hasPermission(this.claimName)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(hasPermission => {
        if (hasPermission && !this.hasPermission) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (!hasPermission) {
          this.viewContainer.clear();
        }
        this.hasPermission = hasPermission;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
