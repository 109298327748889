import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError, tap, take } from 'rxjs/operators';
import { ApplicationUser } from './applicationUser';
import * as jwtDecode from 'jwt-decode';
import { environment } from '../../../environments/environment';
import { TwoFactorStatus, TwoFactorSetupKeys } from '../models/users/twoFactor';
import { LoginResponse } from '../models/users/loginResponse';
import { CandidateMyUserProfile } from '../models/users/userProfile';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // tslint:disable-next-line:variable-name
  user$: BehaviorSubject<ApplicationUser | null> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    // private logService: LogService
  ) {
    // Log.logger = new SecurityLog(logService);
    this.setUser(this.getUserFromStorage());
  }

  private getUserFromStorage(): ApplicationUser | null {
    const userFromSession = window.localStorage.getItem('authenticatedUser');
    if (userFromSession) {
      return !!userFromSession
        ? JSON.parse(userFromSession)
        : null;
    }
  }

  public setUser(user: ApplicationUser): void {
    if (user) {
      window.localStorage.setItem('authenticatedUser',
        JSON.stringify(user));
    } else {
      window.localStorage.removeItem('authenticatedUser');
    }
    this.user$.next(user);
  }

  isLoggedIn(): Observable<boolean> {
    return this.user$.pipe(map(u => !!u));
  }

  isLoggedInWithTwoFactor(): Observable<boolean> {
    return this.user$.pipe(
      map(u => !!u?.authMethods.some(x => x === 'mfa')));
  }

  getAuthorizationHeaderValue(): string {
    return `Bearer ${this.getUserFromStorage().accessToken.tokenValue}`;
  }

  login(userName: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${environment.apiUrl}Account/Login`, {
      userName,
      password
    }).pipe(tap(x => this.handleLoginResponse(x)));
  }

  loginTwoFactor(twoFactorCode: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${environment.apiUrl}Account/LoginTwoFactor`, {
      twoFactorCode
    }).pipe(tap(x => this.handleLoginResponse(x)));
  }

  private handleLoginResponse(resp: LoginResponse): void {
    if (!resp.token) { return; }

    const decoded = jwtDecode(resp.token);
    // a single permission will be received as a string, not an array
    if (!!decoded.ClaimsPortalPermissions && !Array.isArray(decoded.ClaimsPortalPermissions)) {
      decoded.ClaimsPortalPermissions = [decoded.ClaimsPortalPermissions];
    }

    const user = new ApplicationUser(
      decoded.id,
      decoded.sub,
      decoded.name,
      decoded.email,
      decoded.role,
      decoded.ClaimsPortalPermissions && decoded.ClaimsPortalPermissions.length
        ? decoded.ClaimsPortalPermissions.map((x: string) => ({ name: x }))
        : [],
      decoded.amr ? JSON.parse(decoded.amr) : [],
      {
        tokenValue: resp.token,
        tokenExpiration: new Date(decoded.exp * 1000)
      },
      decoded.client_id,
      decoded.Title,
      decoded.CampusId,
      decoded.BrokerAgencyId,
      decoded.Phone
    );

    this.setUser(user);
  }

  logout(): Observable<void> {
    this.setUser(null);
    return this.http.post<void>(`${environment.apiUrl}Account/Logout`, {});
  }

  hasPermission(permissionToCheck: string): Observable<boolean> {
    return this.user$
      .pipe(
        map(user => {
          return user && user.permissions && user.permissions.some(p =>
            p.name === permissionToCheck);
        }),
        catchError(err => { console.log(err); return of(false); })
      );
  }

  renewSession(): Observable<void> {
    return this.http.post<any>(`${environment.apiUrl}Account/Renew`, {})
      .pipe(take(1))
      .pipe(map(x => this.handleLoginResponse(x)));
  }

  getTwoFactorStatus(): Observable<TwoFactorStatus> {
    return this.http.get<TwoFactorStatus>(
      `${environment.apiUrl}Account/TwoFactor/Status`);
  }

  getTwoFactorSetupKeys(): Observable<TwoFactorSetupKeys> {
    return this.http.get<TwoFactorSetupKeys>(
      `${environment.apiUrl}Account/TwoFactor/Setup`);
  }

  enableTwoFactor(twoFactorCode: string) {
    return this.http.post<LoginResponse>(
      `${environment.apiUrl}Account/TwoFactor/Enable`,
      { twoFactorCode })
      .pipe(take(1))
      .pipe(tap(x => this.handleLoginResponse(x)));
  }

  disableTwoFactor() {
    return this.http.post<LoginResponse>(
      `${environment.apiUrl}Account/TwoFactor/Disable`,
      {})
      .pipe(take(1))
      .pipe(tap(x => this.handleLoginResponse(x)));
  }

  resetTwoFactor(): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `${environment.apiUrl}Account/TwoFactor/Reset`,
      {})
      .pipe(take(1))
      .pipe(tap(x => this.handleLoginResponse(x)));
  }

  resetTwoFactorForSpecificUser(userId: number) {
    return this.http.post(
      `${environment.apiUrl}Account/${userId}/TwoFactor/Reset`,
      {});
  }

  updateMyUserProfile(candidate: CandidateMyUserProfile): Observable<void> {
    return this.http.put<any>(`${environment.apiUrl}Account/UpdateProfile`, candidate)
      .pipe(take(1))
      .pipe(map(x => this.handleLoginResponse(x)));
  }
}
