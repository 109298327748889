<mat-toolbar class="mat-elevation-z4" [color]="'primary'">
  <header class="h-100 w-100 d-flex flex-row align-items-center"
    [ngClass]="(showMobileNavIcon ? 'mobile-header' : '')">
    <button type="button" mat-icon-button
      color="primary"
      *ngIf="showMobileNavIcon"
      (click)="mobileNavToggled.emit()">
      <mat-icon>menu</mat-icon>
    </button>
    <a class="logo py-1" [routerLink]="['/']">
      <img src="assets/bmi_logo.jpg" alt="bmi" />
    </a>
    <span class="d-none d-sm-inline-block" *ngIf="loadingCount === 0">
      <ng-container *ngIf="clientName">
        <span>{{clientName}}</span>
      </ng-container>Claims Portal
    </span>
    <div class="ml-auto d-flex align-items-center">
      <ng-container
        *ngIf="authService.isLoggedIn() | async; then loggedIn; else loggedOut">
      </ng-container>
    </div>
  </header>
</mat-toolbar>

<ng-template #loggedIn>
  <ng-container *ngIf="logoBase64Uri && loadingCount === 0">
      <img [src]="logoBase64Uri" class="client-logo" />
  </ng-container>
  <button mat-button [matMenuTriggerFor]="menu" title="{{ user?.userName }}">
    {{ showMobileNavIcon ? 'Account' : user?.userName }}
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item routerLink="/account/manage">
      <span>My Account</span>
    </button>
    <button mat-menu-item routerLink="/account/manage/password">
      <span>Change Password</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="confirmLogout()">
      <span>Logout</span>
    </button>
  </mat-menu>
</ng-template>

<ng-template #loggedOut>

</ng-template>

<ng-template #confirmLogoutDialog>
  <h2 mat-dialog-title>Logout</h2>
  <mat-dialog-content>Are you sure you want to log out?</mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>No</button>
    <button mat-button [mat-dialog-close]="true" routerLink="/account/logout">Yes</button>
  </mat-dialog-actions>
</ng-template>
